import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import { PageContentsBox, StatePageBox } from "component/app/setting";
import { TicketItem } from "component/product/service";

const Service = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const carNumber = state?.state?.carNumber||null;

    const listApi = useGet({
        url:`/service?car_number=${carNumber}`,
        loginType:"login"
    });
    const listData = listApi?.data?.car_wash_reservations;

    const [nickName,setNickName] =  useState(null);
    const [cash,setCash] =  useState(null);

    useEffect(() => {
        if(listApi){
            if(listApi?.data?.nickname){
                setNickName(listApi?.data?.nickname);
            }
            if(listApi?.data?.cash){
                setCash(listApi?.data?.cash);
            }
            if(listApi?.success == false || listApi?.success == "false" || listData?.length <= 0){
                navigate("/service/order",{state: {carNumber:carNumber,notMember:true}});
            }
        }
    }, [listApi]);

    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={carNumber}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                        title={"<span class='gTextColor'>보유하고 계신 이용권</span>을 선택해주세요."}
                        subTitle={`${nickName ? nickName : "고객"}님, 환영합니다!`}
                    />
                    <PageContentsBox addClass="pageContentsScrollBox">
                        {listData && listData?.map((item,i)=>(
                            <TicketItem
                                key={i}
                                addClass=""
                                type={item.type}
                                title={item.name}
                                text={item.contents}
                                btnText="이용권 사용"
                                func={()=>{
                                    navigate("/service/order",{state: {page:1,id:item.id,name:item.name,carNumber:carNumber,cash:cash,productId:item.car_wash_product_id,productType:item.product_type}});
                                }}
                            />
                        ))}
                    </PageContentsBox>
            </ContentsSection>
            <BtnBox
                boxType="fixed"
                addClass={"col2"}
            >
                <BtnItem
                    addClass="subBtn"
                    contents={"<img src='/assets/images/icon/ch.svg'/>차량번호 변경"}
                    disabled={false}
                    func={() => {navigate("/carNumber")}}
                />
                <BtnItem
                    addClass=""
                    contents={"다른 이용권 보기"}
                    disabled={false}
                    func={() => {navigate("/service/order",{state: {carNumber:carNumber,cash:cash}})}}
                />
            </BtnBox>
        </PageSizing>
    );
};

export default Service;